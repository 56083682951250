import * as React from 'react';

export const ListenerLogoSvg = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="34" rx="11.3333" fill="white" />
      <rect width="34" height="34" rx="11.3333" fill="white" />
      <path
        d="M4.42753 8.20229L1.90998 6.8014C1.83892 6.7608 1.74756 6.81155 1.74756 6.89276V9.73516C1.74756 9.81637 1.83892 9.86713 1.90998 9.82652L4.42753 8.42563C4.51889 8.38502 4.51889 8.25305 4.42753 8.20229Z"
        fill="#73C6D5"
      />
      <path
        d="M7.9705 3.32729L5.45295 1.9264C5.38189 1.8858 5.29053 1.93655 5.29053 2.01776V4.86016C5.29053 4.94137 5.38189 4.99213 5.45295 4.95152L7.9705 3.55063C8.06186 3.51002 8.06186 3.37805 7.9705 3.32729Z"
        fill="#73C6D5"
      />
      <path
        d="M20.2133 11.8863V1.02429L10.4883 6.4553L20.2133 11.8863Z"
        fill="#273249"
      />
      <path
        d="M16.2537 14.0152L7.24943 8.9903C6.98549 8.84818 6.66064 9.0309 6.66064 9.33544V19.5072C6.66064 19.8117 6.98549 19.9944 7.24943 19.8523L16.2537 14.8375C16.5786 14.6649 16.5786 14.198 16.2537 14.0152Z"
        fill="#73C6D5"
      />
      <path
        d="M4.42753 8.20229L1.90998 6.8014C1.83892 6.7608 1.74756 6.81155 1.74756 6.89276V9.73516C1.74756 9.81637 1.83892 9.86713 1.90998 9.82652L4.42753 8.42563C4.51889 8.38502 4.51889 8.25305 4.42753 8.20229Z"
        fill="#73C6D5"
      />
      <path
        d="M7.9705 3.32729L5.45295 1.9264C5.38189 1.8858 5.29053 1.93655 5.29053 2.01776V4.86016C5.29053 4.94137 5.38189 4.99213 5.45295 4.95152L7.9705 3.55063C8.06186 3.51002 8.06186 3.37805 7.9705 3.32729Z"
        fill="#73C6D5"
      />
      <path
        d="M20.2133 11.8863V1.02429L10.4883 6.4553L20.2133 11.8863Z"
        fill="#273249"
      />
      <path
        d="M16.2537 14.0152L7.24943 8.9903C6.98549 8.84818 6.66064 9.0309 6.66064 9.33544V19.5072C6.66064 19.8117 6.98549 19.9944 7.24943 19.8523L16.2537 14.8375C16.5786 14.6649 16.5786 14.198 16.2537 14.0152Z"
        fill="#73C6D5"
      />
      <rect width="34" height="34" rx="11.3333" fill="#273249" />
      <path
        d="M18.1534 14.0487V7.34717L11.6489 10.6986L18.1534 14.0487Z"
        fill="white"
      />
      <path
        d="M15.5062 15.3655L9.48317 12.2657C9.44436 12.2441 9.40071 12.2326 9.35628 12.2323C9.31184 12.232 9.26806 12.243 9.22898 12.2641C9.18991 12.2853 9.1568 12.3159 9.13273 12.3533C9.10867 12.3906 9.09442 12.4335 9.09131 12.4778V18.7561C9.09438 18.8004 9.10859 18.8432 9.1326 18.8806C9.15661 18.9179 9.18966 18.9486 9.22868 18.9698C9.2677 18.991 9.31144 19.002 9.35584 19.0018C9.40024 19.0016 9.44388 18.9902 9.48271 18.9687L15.5062 15.8739C15.5543 15.8511 15.595 15.8151 15.6234 15.7701C15.6519 15.7251 15.667 15.6729 15.667 15.6197C15.667 15.5664 15.6519 15.5143 15.6234 15.4693C15.595 15.4243 15.5543 15.3883 15.5062 15.3655Z"
        fill="#73C6D5"
      />
      <path
        d="M7.59345 11.7754L5.90952 10.9107C5.89869 10.9047 5.88653 10.9015 5.87415 10.9015C5.86178 10.9014 5.84959 10.9045 5.83871 10.9104C5.82783 10.9163 5.81861 10.9248 5.81189 10.9352C5.80518 10.9456 5.80119 10.9575 5.80029 10.9699V12.723C5.80119 12.7353 5.80518 12.7472 5.81189 12.7576C5.81861 12.768 5.82783 12.7766 5.83871 12.7825C5.84959 12.7883 5.86178 12.7914 5.87415 12.7914C5.88653 12.7913 5.89869 12.7881 5.90952 12.7821L7.59345 11.9174C7.60698 11.9111 7.61844 11.9011 7.62647 11.8885C7.63449 11.8759 7.63876 11.8613 7.63876 11.8464C7.63876 11.8315 7.63449 11.8169 7.62647 11.8043C7.61844 11.7917 7.60698 11.7817 7.59345 11.7754Z"
        fill="#73C6D5"
      />
      <path
        d="M9.96357 8.76943L8.27963 7.90472C8.26881 7.89873 8.25665 7.89556 8.24427 7.89551C8.2319 7.89545 8.21971 7.8985 8.20883 7.9044C8.19795 7.91029 8.18872 7.91884 8.18201 7.92923C8.17529 7.93963 8.17131 7.95154 8.17041 7.96388V9.71699C8.17131 9.72933 8.17529 9.74126 8.18201 9.75165C8.18872 9.76205 8.19795 9.77058 8.20883 9.77647C8.21971 9.78237 8.2319 9.78543 8.24427 9.78538C8.25665 9.78532 8.26881 9.78215 8.27963 9.77615L9.96357 8.91144C9.9771 8.90515 9.98856 8.89513 9.99658 8.88255C10.0046 8.86997 10.0089 8.85536 10.0089 8.84044C10.0089 8.82551 10.0046 8.8109 9.99658 8.79832C9.98856 8.78575 9.9771 8.77572 9.96357 8.76943Z"
        fill="#73C6D5"
      />
      <path
        d="M22.9313 20.617H18.1526V17.2546L11.613 20.617V23.8293C11.6453 24.6085 11.9854 25.3432 12.5585 25.8721C13.1316 26.4011 13.8912 26.6812 14.6705 26.651H22.9304C23.7636 26.6831 24.5756 26.3837 25.1885 25.8183C25.8013 25.2529 26.1651 24.4676 26.2 23.6345C26.1653 22.8014 25.8018 22.016 25.1891 21.4504C24.5764 20.8848 23.7645 20.5851 22.9313 20.617Z"
        fill="white"
      />
    </svg>
  );
};

export const ZoomLogoSvg = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="34" rx="10" fill="url(#paint0_linear)" />
    <path
      d="M6 11.6047V19.7025C6.00715 21.5337 7.49521 23.0074 9.30995 23H21.054C21.3878 23 21.6564 22.73 21.6564 22.4017V14.3039C21.6493 12.4727 20.1612 10.9989 18.3468 11.0064H6.60272C6.26889 11.0064 6.00029 11.2764 6.00029 11.6047H6ZM22.4039 14.7636L27.2525 11.2036C27.6736 10.8534 28 10.9408 28 11.5757V22.431C28 23.1532 27.6007 23.0658 27.2525 22.803L22.4039 19.2502V14.7636Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="17"
        y1="0"
        x2="17"
        y2="34"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#4FA4FF" />
        <stop offset="1" stopColor="#3F84FD" />
      </linearGradient>
    </defs>
  </svg>
);

export const ZoomTextSvg = () => (
  <svg
    width="50"
    height="11"
    viewBox="0 0 50 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9951 1.60875C18.9143 0.53625 17.5009 0 16.0736 0C14.6603 0 13.233 0.53625 12.1522 1.60875C9.9906 3.75375 9.9906 7.2325 12.1522 9.39125C14.3139 11.5363 17.8196 11.5363 19.9951 9.39125C22.1567 7.24625 22.1567 3.75375 19.9951 1.60875ZM18.4293 7.8375C17.1268 9.13 15.0205 9.13 13.7319 7.8375C12.4294 6.545 12.4294 4.455 13.7319 3.17625C15.0344 1.88375 17.1406 1.88375 18.4293 3.17625C19.7179 4.455 19.7179 6.545 18.4293 7.8375Z"
      fill="#2D8CFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.6349 1.60875C30.5541 0.53625 29.1408 0 27.7135 0C26.3002 0 24.8729 0.53625 23.7921 1.60875C21.6305 3.75375 21.6305 7.2325 23.7921 9.39125C25.9537 11.5363 29.4595 11.5363 31.6349 9.39125C33.7966 7.24625 33.7966 3.75375 31.6349 1.60875ZM30.0691 7.8375C28.7666 9.13 26.6604 9.13 25.3718 7.8375C24.0693 6.545 24.0693 4.455 25.3718 3.17625C26.6743 1.88375 28.7805 1.88375 30.0691 3.17625C31.3578 4.455 31.3578 6.545 30.0691 7.8375Z"
      fill="#2D8CFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.21705 10.9725L2.77132 11H11.0853L11.0576 10.45C10.9883 9.5425 10.337 8.8825 9.42248 8.8275L8.86822 8.8H3.87985L10.531 2.2L10.5033 1.65C10.4617 0.7425 9.78275 0.0687496 8.86822 0.0274996L8.31395 0H0L0.0277128 0.55C0.0969957 1.44375 0.762112 2.1175 1.66279 2.1725L2.21705 2.2H7.20543L0.554264 8.8L0.581976 9.35C0.637403 10.2575 1.30252 10.9175 2.21705 10.9725Z"
      fill="#2D8CFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.1564 3.3C40.3504 3.63 40.4058 3.9875 40.4335 4.4L40.4612 4.95V8.8L40.4889 9.35C40.5444 10.2438 41.2095 10.9175 42.124 10.9725L42.6783 11V4.95L42.706 4.4C42.7337 3.9875 42.7891 3.61625 42.9831 3.28625C43.3711 2.62625 44.0778 2.2 44.8953 2.2C45.7129 2.2 46.4334 2.64 46.8214 3.3C47.0154 3.63 47.0708 4.00125 47.0847 4.4L47.1124 4.95V8.8L47.1401 9.35C47.1955 10.2575 47.8606 10.9175 48.7752 10.9725L49.3294 11V4.95V4.4C49.3294 1.96625 47.3479 0 44.8953 0C43.5651 0 42.3873 0.5775 41.5697 1.485C40.7522 0.5775 39.5744 0 38.2442 0C37.3296 0 36.4705 0.275 35.7638 0.75625C35.3343 0.275 34.3643 0 33.8101 0V11L34.3643 10.9725C35.2927 10.9175 35.9578 10.2575 35.9994 9.35L36.0271 8.8V4.95L36.0548 4.4C36.0825 3.9875 36.138 3.61625 36.332 3.3C36.7199 2.64 37.4266 2.2 38.2442 2.2C39.0617 2.2 39.7822 2.64 40.1564 3.3Z"
      fill="#2D8CFF"
    />
  </svg>
);