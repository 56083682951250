import React from "react"
import { FullListenerLogo } from "../assets/FullListenerLogo"

import { ListenerLogoSvg, ZoomLogoSvg, ZoomTextSvg } from "../assets/Signup"

import styled from "styled-components"

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f1f5fd;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BlueCap = styled.div`
  width: 678px;
  height: 12px;
  background: #4875e2;
  border-radius: 11px 11px 0px 0px;

  @media (max-width: 720px) {
    width: calc(100vw - 40px);
  }
`

const Wrapper = styled.div`
  width: 680px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  background: #ffffff;

  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(31, 38, 59, 0.06),
    0px 16px 32px rgba(31, 38, 59, 0.06);
  border-radius: 12px;

  @media (max-width: 720px) {
    width: calc(100vw - 40px);
  }
`

const BlueContainer = styled.div`
  border-radius: 6px;
  background: var(--Hover, #f1f5fd);
  margin-top: 65px;
  margin-bottom: 81px;

  @media (max-width: 530px) {
    margin-top: 32.5px;
    margin-bottom: 40.5px;
  }

`

const SorryText = styled.div`
  display: flex;

  height: 72px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Open Sans;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */

  margin: 22px 20px;

  @media (max-width: 530px) {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 11px 10px;
  }

  @media (max-width: 420px) {
    font-size: 10px;
  }

 
`

const WriteToUs = styled.a`
  color: #000;
  text-decoration-line: underline;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const TextContainer = styled.div``

const H1Text = styled.h1`
  color: #1f263b;
  font-size: 32px;
  line-height: 1.7;
  font-weight: bold;
  font-family: Open Sans;
  margin-top: 69px;
  margin-bottom: 16px;

  @media (max-width: 530px) {
    font-size: 24px;
    margin-top: 35px;
    margin-bottom: 8px;
  }

  @media (max-width: 420px) {
    font-size: 20px;
  }
`

const PlusIcon = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: #c4c4c4;
  margin-left: 15px;
  margin-right: 15px;

  @media (max-width: 530px) {
    font-size: 13.5px;
    margin-left: 8px;
    margin-right: 8px;
  }

  @media (max-width: 420px) {
    font-size: 11.25px;
  }
`

const SubHeader = styled.div`
  color: #848792;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 56px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;

  @media (max-width: 530px) {
    font-size: 15px;
    margin-bottom: 28px;
  }

  @media (max-width: 420px) {
    font-size: 12.5px;
  }
`

const ZoomIntegration = styled.div`
  margin-top: 18px;
  color: #1f263b;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: 530px) {
    margin-top: 9px;
    font-size: 12px;
    svg {
      width: 37.5px;
      height: 8.25px;
    }
  }

  @media (max-width: 420px) {
    font-size: 10px;
    svg {
      width: 31.25px;
      height: 6.8px;
    }
  }

`

const ZoomListenerIcons = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 530px) {
    svg {
      width: 25.5px;
      height: 25.5px;
    }
  }

  @media (max-width: 420px) {
    
    svg {
      width: 21.25px;
      height: 21.25px;
    }
  }
`
const License = styled.span`
  text-align: center;
  background-image: linear-gradient(90deg, #4a8cf3 0%, #e77640 100%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.06em;

  @media (max-width: 530px) {
    font-size: 12px;
  }

  @media (max-width: 420px) {
    font-size: 10px;
  }
`



const PrivacyText = styled.p`
 
  color: #848792;
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 19.2px */
  margin-bottom: 12px;

  @media (max-width: 600px) {
    font-size: 10.5px;
  }

  @media (max-width: 530px) {
    font-size: 8px;
    margin-bottom: 8px;
  }

  @media (max-width: 420px) {
    font-size: 7px;
  }
`

const PrivacyLink = styled.a`

  color: #848792;
  text-decoration: underline;
  &:hover {
    color: #cfcfcf;
    text-decoration: underline;
  }

`

const LoginPage = () => {
  return (
    <>
      <Container>
        <Wrapper>
          <BlueCap />

          <H1Text>Sign up for Listener</H1Text>
          <SubHeader>
            Capture the most important highlights of your <br />
            video meetings, and share them with your team
          </SubHeader>
          <ZoomListenerIcons>
            <ZoomLogoSvg />
            <PlusIcon>+</PlusIcon>
            <ListenerLogoSvg />
          </ZoomListenerIcons>
          <ZoomIntegration>
            Listener works best with <ZoomTextSvg /> <License>LICENSED</License>{" "}
            accounts
          </ZoomIntegration>

          <BlueContainer>
            <SorryText>
              Sorry! Currently we are not on-boarding new users. For
              <TextContainer>
                more information, please{" "}
                <WriteToUs href="mailto:hi@truesparrow.com">
                  contact us
                </WriteToUs>
                .
              </TextContainer>
            </SorryText>
          </BlueContainer>

          <PrivacyText>
            By using Listener, you are agreeing to the{" "}
            <PrivacyLink href="https://listener.app/terms" target="_blank">
              Terms of Service
            </PrivacyLink>
            ,{" "}
            <PrivacyLink href="https://listener.app/privacy" target="_blank">
              Privacy Policy
            </PrivacyLink>
            {" and use of "}
            <PrivacyLink href="https://listener.app/privacy" target="_blank">
              Cookies.
            </PrivacyLink>
          </PrivacyText>
        </Wrapper>
      </Container>
    </>
  )
}
export default LoginPage
