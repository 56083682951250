import React from "react"
export const FullListenerLogo = ({ width, height }) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="96" height="32" viewBox="0 0 96 32" fill="none">
  <path d="M17.3929 14.2528V4.81714L8.23474 9.53592L17.3929 14.2528Z" fill="#273249"/>
  <path d="M13.6654 16.1073L5.18515 11.7428C5.13051 11.7124 5.06905 11.6962 5.00649 11.6958C4.94393 11.6954 4.88229 11.7108 4.82727 11.7406C4.77225 11.7704 4.72563 11.8136 4.69175 11.8662C4.65786 11.9187 4.6378 11.979 4.63342 12.0415V20.8812C4.63775 20.9436 4.65775 21.0038 4.69156 21.0564C4.72537 21.109 4.77189 21.1522 4.82684 21.1821C4.88178 21.2119 4.94336 21.2274 5.00588 21.2271C5.0684 21.2268 5.12984 21.2108 5.1845 21.1804L13.6654 16.823C13.7332 16.791 13.7904 16.7403 13.8305 16.6769C13.8706 16.6136 13.8919 16.5401 13.8919 16.4652C13.8919 16.3902 13.8706 16.3168 13.8305 16.2534C13.7904 16.19 13.7332 16.1394 13.6654 16.1073Z" fill="#73C6D5"/>
  <path d="M2.5246 11.0522L0.153667 9.83474C0.138421 9.82631 0.121297 9.82186 0.103874 9.82178C0.0864507 9.8217 0.0692884 9.826 0.0539683 9.83429C0.0386481 9.84259 0.0256648 9.85462 0.0162116 9.86926C0.00675837 9.88389 0.00114173 9.90067 -0.00012207 9.91805V12.3864C0.00114173 12.4038 0.00675837 12.4205 0.0162116 12.4352C0.0256648 12.4498 0.0386481 12.4618 0.0539683 12.4701C0.0692884 12.4784 0.0864507 12.4827 0.103874 12.4827C0.121297 12.4826 0.138421 12.4781 0.153667 12.4697L2.5246 11.2522C2.54365 11.2433 2.55978 11.2292 2.57109 11.2115C2.58239 11.1938 2.5884 11.1732 2.5884 11.1522C2.5884 11.1312 2.58239 11.1106 2.57109 11.0929C2.55978 11.0752 2.54365 11.0611 2.5246 11.0522Z" fill="#73C6D5"/>
  <path d="M5.86188 6.81982L3.49094 5.60232C3.4757 5.59389 3.45858 5.58943 3.44115 5.58936C3.42373 5.58928 3.40657 5.59357 3.39125 5.60187C3.37593 5.61017 3.36294 5.6222 3.35349 5.63684C3.34403 5.65147 3.33842 5.66825 3.33716 5.68563V8.15395C3.33842 8.17133 3.34403 8.18813 3.35349 8.20276C3.36294 8.2174 3.37593 8.22941 3.39125 8.23771C3.40657 8.24601 3.42373 8.25032 3.44115 8.25025C3.45858 8.25017 3.4757 8.2457 3.49094 8.23726L5.86188 7.01976C5.88094 7.01091 5.89706 6.99679 5.90837 6.97908C5.91967 6.96137 5.92567 6.9408 5.92567 6.91979C5.92567 6.89878 5.91967 6.87821 5.90837 6.8605C5.89706 6.84279 5.88094 6.82867 5.86188 6.81982Z" fill="#73C6D5"/>
  <path d="M24.1206 23.5005H17.3922V18.7664L8.18469 23.5005V28.0233C8.23018 29.1204 8.7089 30.1548 9.51588 30.8995C10.3229 31.6443 11.3922 32.0387 12.4895 31.9962H24.1193C25.2924 32.0414 26.4357 31.6199 27.2986 30.8238C28.1615 30.0277 28.6736 28.922 28.7227 27.749C28.6739 26.576 28.1621 25.4702 27.2995 24.6739C26.4368 23.8775 25.2937 23.4556 24.1206 23.5005Z" fill="#273249"/>
  <path d="M30.6101 17.2849H23.1128V5.09574L26.0015 3.60718V14.6198H30.6069C30.9749 14.6056 31.3335 14.7378 31.6043 14.9875C31.875 15.2372 32.0357 15.5841 32.0512 15.952C32.0359 16.3195 31.8757 16.6661 31.6056 16.9158C31.3356 17.1656 30.9777 17.2983 30.6101 17.2849Z" fill="#273249"/>
  <path d="M30.6274 12.4557C30.2593 12.47 29.9007 12.3377 29.6299 12.088C29.3592 11.8383 29.1985 11.4915 29.183 11.1235L29.167 5.76137C29.1823 5.39328 29.343 5.04629 29.6137 4.79645C29.8845 4.5466 30.2432 4.4143 30.6113 4.42853C30.9795 4.4143 31.3382 4.5466 31.609 4.79645C31.8797 5.04629 32.0404 5.39328 32.0557 5.76137L32.0717 11.1235C32.0562 11.4915 31.8955 11.8383 31.6248 12.088C31.3541 12.3377 30.9954 12.47 30.6274 12.4557Z" fill="#273249"/>
  <path d="M30.7575 0H30.496C29.6368 0 28.9402 0.696575 28.9402 1.55584C28.9402 2.41511 29.6368 3.11169 30.496 3.11169H30.7575C31.6167 3.11169 32.3133 2.41511 32.3133 1.55584C32.3133 0.696575 31.6167 0 30.7575 0Z" fill="#273249"/>
  <path d="M38.7091 17.4597C37.9199 17.5143 37.1312 17.3472 36.432 16.9772C35.7328 16.6073 35.151 16.0493 34.7522 15.3662C34.6556 15.1771 34.6109 14.9659 34.6225 14.754C34.6341 14.542 34.7016 14.3369 34.8182 14.1596C34.9348 13.9822 35.0964 13.8389 35.2863 13.7442C35.4763 13.6496 35.688 13.6069 35.8998 13.6207H35.9524C36.1858 13.613 36.4172 13.6671 36.6231 13.7775C36.829 13.8879 37.0021 14.0506 37.125 14.2493C37.2875 14.5256 37.5318 14.7447 37.8242 14.8761C38.1166 15.0075 38.4426 15.0449 38.7571 14.983C39.0396 14.9392 39.3028 14.8128 39.5137 14.6198C39.7245 14.4268 39.8735 14.1757 39.9419 13.8981C40.0006 13.6141 39.9658 13.3188 39.843 13.0561C39.7201 12.7934 39.5157 12.5774 39.2601 12.4403L39.2441 12.4307C38.9585 12.295 38.6626 12.1821 38.3592 12.093C37.1526 11.6906 35.0123 10.9774 34.592 8.8442C34.5028 8.34678 34.5154 7.83643 34.6291 7.34402C34.7427 6.85162 34.9551 6.38738 35.2532 5.97937C35.5514 5.57135 35.9292 5.22803 36.3638 4.97017C36.7984 4.71231 37.2808 4.54526 37.7818 4.47911C38.5381 4.34395 39.3172 4.42694 40.028 4.71836C40.7389 5.00977 41.352 5.49755 41.7958 6.12466L39.471 7.32936C39.327 7.16762 39.1458 7.04334 38.9431 6.96721C38.7403 6.89108 38.5222 6.86537 38.3073 6.89233C38.1383 6.91031 37.9747 6.9625 37.8265 7.04571C37.6783 7.12891 37.5486 7.24141 37.4452 7.37635C37.3419 7.51129 37.267 7.66584 37.2253 7.8306C37.1836 7.99537 37.1759 8.1669 37.2026 8.33476C37.3096 9.01208 37.8735 9.31709 39.2653 9.78167C39.7538 9.92473 40.2263 10.118 40.675 10.3584C41.2723 10.6848 41.7698 11.167 42.1147 11.7538C42.4595 12.3406 42.6387 13.0099 42.6333 13.6905C42.5705 14.6962 42.1324 15.6418 41.4057 16.3398C40.6789 17.0378 39.7165 17.4375 38.7091 17.4597Z" fill="#273249"/>
  <path d="M50.6035 4.60798H44.7171C44.3486 4.58965 43.9871 4.71342 43.7072 4.95378C43.4272 5.19414 43.2501 5.53276 43.2125 5.89981C43.2146 6.08546 43.2539 6.26883 43.3281 6.43902C43.4023 6.60921 43.5099 6.76277 43.6445 6.89063C43.7792 7.01849 43.9381 7.11806 44.1118 7.18341C44.2856 7.24877 44.4708 7.27859 44.6563 7.27112H46.3293V17.2829H49.2187V7.27112H50.5432C50.9117 7.28946 51.2732 7.16568 51.5532 6.92532C51.8331 6.68496 52.0102 6.34634 52.0478 5.97929C52.0457 5.79358 52.0064 5.61018 51.9321 5.43995C51.8579 5.26972 51.7503 5.11611 51.6156 4.98824C51.4809 4.86037 51.3219 4.76083 51.148 4.69551C50.9742 4.6302 50.789 4.60043 50.6035 4.60798Z" fill="#273249"/>
  <path d="M83.967 5.93946C83.9515 5.57148 83.7908 5.22465 83.5201 4.97494C83.2493 4.72524 82.8907 4.59301 82.5226 4.60724H76.4723V17.2841H82.5214C82.8894 17.2983 83.2481 17.1661 83.5188 16.9164C83.7895 16.6666 83.9502 16.3198 83.9657 15.9518C83.9502 15.5839 83.7895 15.237 83.5188 14.9873C83.2481 14.7376 82.8894 14.6054 82.5214 14.6196H79.3603V12.2519H81.9446C82.3127 12.2661 82.6713 12.1339 82.942 11.8842C83.2128 11.6345 83.3735 11.2877 83.389 10.9197C83.3735 10.5517 83.2128 10.2049 82.942 9.95518C82.6713 9.70548 82.3127 9.57325 81.9446 9.58748H79.361V7.27038H82.5214C82.8894 7.28496 83.2482 7.15307 83.5192 6.9036C83.7901 6.65413 83.9512 6.30743 83.967 5.93946Z" fill="#273249"/>
  <path d="M61.3476 5.93946C61.3321 5.57148 61.1714 5.22465 60.9007 4.97494C60.63 4.72524 60.2713 4.59301 59.9033 4.60724H53.8529V17.2841H59.9033C60.2713 17.2983 60.63 17.1661 60.9007 16.9164C61.1714 16.6666 61.3321 16.3198 61.3476 15.9518C61.3321 15.5839 61.1714 15.237 60.9007 14.9873C60.63 14.7376 60.2713 14.6054 59.9033 14.6196H56.7422V12.2519H59.3259C59.6939 12.2661 60.0526 12.1339 60.3233 11.8842C60.5941 11.6345 60.7548 11.2877 60.7702 10.9197C60.7548 10.5517 60.5941 10.2049 60.3233 9.95518C60.0526 9.70548 59.6939 9.57325 59.3259 9.58748H56.7422V7.27038H59.9033C60.2711 7.28462 60.6296 7.15256 60.9003 6.90313C61.1709 6.6537 61.3318 6.3072 61.3476 5.93946Z" fill="#273249"/>
  <path d="M95.3641 15.4116L92.9932 12.0308C93.5993 11.7177 94.114 11.2529 94.4871 10.6818C94.8603 10.1106 95.0792 9.45258 95.1225 8.77172C95.1212 8.20989 95.0066 7.65408 94.7856 7.13755C94.5646 6.62103 94.2416 6.1544 93.8361 5.76556C93.4306 5.37672 92.9508 5.07366 92.4254 4.87452C91.9001 4.67538 91.34 4.58425 90.7786 4.60657H86.3308V17.275H89.2202V12.6286H89.9712L92.9316 16.8508C93.0697 17.0425 93.2518 17.1983 93.4625 17.3051C93.6732 17.4119 93.9065 17.4666 94.1427 17.4647C94.3904 17.4838 94.6385 17.4333 94.859 17.3189C95.0795 17.2045 95.2636 17.0307 95.3906 16.8172C95.5176 16.6037 95.5824 16.359 95.5778 16.1106C95.5731 15.8623 95.4991 15.6202 95.3641 15.4116ZM89.2195 7.27354H90.7779C90.9632 7.25953 91.1494 7.28392 91.3248 7.34519C91.5002 7.40646 91.661 7.5033 91.7973 7.62963C91.9335 7.75595 92.0422 7.90904 92.1165 8.07932C92.1909 8.2496 92.2292 8.43341 92.2292 8.61921C92.2292 8.80501 92.1909 8.98879 92.1165 9.15908C92.0422 9.32936 91.9335 9.48247 91.7973 9.60879C91.661 9.73512 91.5002 9.83195 91.3248 9.89323C91.1494 9.9545 90.9632 9.97889 90.7779 9.96488H89.2195V7.27354Z" fill="#273249"/>
  <path d="M71.146 16.8697L66.6822 10.0094V17.285H63.7922V5.97564C63.8102 5.54805 63.9971 5.14505 64.3118 4.85506C64.6266 4.56506 65.0435 4.41176 65.4711 4.42877C65.7523 4.42557 66.03 4.49172 66.2796 4.62137C66.5291 4.75102 66.7429 4.94017 66.902 5.17209L70.9019 11.6338V4.60819H73.7912V16.1348C73.7757 16.5024 73.6151 16.8489 73.3446 17.0984C73.0741 17.3478 72.7158 17.4799 72.3481 17.4657C72.1149 17.4682 71.8845 17.4155 71.6755 17.312C71.4666 17.2084 71.2852 17.0568 71.146 16.8697Z" fill="#273249"/>
</svg>
    );
  };